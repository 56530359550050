export const NavigationItems = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "About Me",
    path: "/about/",
  },
  {
    label: "Experience",
    path: "/experience/",
  },
  {
    label: "Skills",
    path: "/skills/",
  },
];

export const ContactBtn = {
  label: "Contact Me",
  path: "/contact/",
};
