import React from "react";
import styled from "styled-components";
import { SocialItems } from "../../data/social";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IsMobile } from "../../hooks/isMobile";
import { PopupButton } from "react-calendly";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import Link from "@mui/material/Link";

const SocialRow = styled.div<{ alignCenter }>`
  display: flex;
  justify-content: space-around;
  width: 40%;
  position: absolute;
  bottom: 0;
  right: ${(alignCenter) => (alignCenter ? "unset" : "6%")};
`;

const MobileSocialRow = styled.div`
  right: 6%;
  height: 55%;
  display: flex;
  position: absolute;
  flex-direction: column-reverse;
  justify-content: space-around;
  top: 14vh;
`;

const SocialItem = styled.h1`
  :hover {
    cursor: pointer;
  }
`;

const Data = () => {
  return (
    <>
      {SocialItems.map((item) => {
        return (
          <SocialItem>
            <Link href={item.action} target={"_blank"} color={"#000"}>
              <FontAwesomeIcon icon={item.icon} />
            </Link>
          </SocialItem>
        );
      })}
      <PopupButton
        pageSettings={{
          backgroundColor: "eace6b",
          hideEventTypeDetails: false,
          hideGdprBanner: true,
          hideLandingPageDetails: false,
          primaryColor: "000000",
          textColor: "000000",
        }}
        styles={{
          padding: 0,
          background: "transparent",
          border: "none",
        }}
        text={
          <SocialItem>
            <FontAwesomeIcon icon={faVideo} />
          </SocialItem>
        }
        url="https://calendly.com/deekshajuneja?primary_color=eace6b"
      />
    </>
  );
};

export const Social = ({ alignCenter }: { alignCenter?: boolean }) => {
  const isMobile = IsMobile();
  return isMobile ? (
    <MobileSocialRow>
      <Data />
    </MobileSocialRow>
  ) : (
    <SocialRow alignCenter={alignCenter}>
      <Data />
    </SocialRow>
  );
};
