import React from "react";
import styled from "styled-components";

const AbsoluteContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 1000;
`;

const PacContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const PacMan = styled.div`
  position: relative;
  > div:nth-child(1),
  > div:nth-child(2) {
    width: 0;
    height: 0;
    border: 37.5px solid #fed75a;
    border-right-color: transparent;
    border-radius: 50%;
    left: -60px;
    -webkit-animation: rotate_pacman_half_up 0.5s infinite;
    animation: rotate_pacman_half_up 0.5s infinite;
    position: relative;
  }
  > div:nth-child(2) {
    -webkit-animation-name: rotate_pacman_half_down;
    animation-name: rotate_pacman_half_down;
    margin-top: -75px;
  }
  > div:nth-child(3),
  > div:nth-child(4),
  > div:nth-child(5) {
    -webkit-animation: pacman-balls 1s infinite linear;
    animation: pacman-balls 1s infinite linear;
    background-color: #eace6b;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 30px;
    left: 75px;
  }
  > div:nth-child(3) {
    -webkit-animation-delay: -0.66s;
    animation-delay: -0.66s;
  }
  > div:nth-child(4) {
    -webkit-animation-delay: -0.33s;
    animation-delay: -0.33s;
  }
  /*Pacman Animation Keyframes*/
  @-webkit-keyframes rotate_pacman_half_up {
    0% {
      transform: rotate(270deg);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
  @keyframes rotate_pacman_half_up {
    0% {
      transform: rotate(270deg);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
  @-webkit-keyframes rotate_pacman_half_down {
    0% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(90deg);
    }
  }
  @keyframes rotate_pacman_half_down {
    0% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(90deg);
    }
  }
  @-webkit-keyframes pacman-balls {
    75% {
      opacity: 0.7;
    }
    100% {
      transform: translateX(-93.75px);
    }
  }
  @keyframes pacman-balls {
    75% {
      opacity: 0.7;
    }
    100% {
      transform: translateX(-93.75px);
    }
  }
`;

export const Loading = () => {
  return (
    <AbsoluteContainer>
      <PacContainer>
        <PacMan>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </PacMan>
      </PacContainer>
    </AbsoluteContainer>
  );
};
