import React, {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import "../styles/global.css";
import { siteMeta } from "../data/seo";
import favicon from "../assets/icon.svg";
import { Header } from "./header/header";
import { Location } from "@reach/router";
import { Loading } from "./loading";

const Wrapper = styled.div<{ bgcolor: string }>`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100vh;
  background-position: bottom;
  padding: 0;
  position: relative;
  @media (max-width: 700px) {
    overflow: hidden;
    padding: 3%;
  }
  @media (max-width: 425px) {
    padding: 5vw;
  }
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "#eace6b")};
`;

export const Layout = ({
  children,
  bgcolor,
  displayLoader,
}: {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  bgcolor?: string;
  displayLoader?: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(displayLoader);
  }, [displayLoader]);
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: "description", content: siteMeta.description },
              { name: "keywords", content: siteMeta.keywords.join(", ") },
              { name: "author", content: siteMeta.author },
              { name: "copyright", content: siteMeta.copyright },
            ]}
            link={[
              { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
              { rel: "preconnect", href: "https://fonts.googleapis.com" },
              {
                name: "viewport",
                content: "initial-scale=1.0, width=device-width",
              },
              {
                rel: "preconnect",
                href: "https://fonts.gstatic.com",
                crossOriginIsolated: true,
              },
              {
                rel: "stylesheet",
                href: "https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Sacramento&family=Warnes&display=swap",
              },
            ]}
          />
          <Location>
            {({ location }) => {
              return (
                <>
                  {loading && <Loading />}
                  <Wrapper
                    className={location.pathname === "/" ? "cutBackground" : ""}
                    bgcolor={bgcolor}
                  >
                    <Header bgcolor={bgcolor} />
                    {children}
                  </Wrapper>
                </>
              );
            }}
          </Location>
        </>
      )}
    />
  );
};
