import { faAt, faVideo } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn, faInstagram } from "@fortawesome/free-brands-svg-icons";

export const SocialItems = [
  {
    label: "Email",
    icon: faAt,
    action: "mailto:juneja.deeksha@gmail.com",
  },
  {
    label: "Linkedin",
    icon: faLinkedinIn,
    action: "https://www.linkedin.com/in/deekshajuneja",
  },
  {
    label: "Skills",
    icon: faInstagram,
    action: "https://www.instagram.com/deekshajuneja/",
  },
];
